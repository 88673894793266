import React from "react";
import Layout from "../components/general/Layout";
import Map from "../components/experiences/map";
import { Helmet } from "react-helmet";

const MapExperience = () => {
    return (
        <Layout>
            <Helmet>
                <title>Visitor Map</title>
                <meta name="description" content="AR/VR visitor map" />
                <script src="https://unpkg.com/aframe-event-set-component@4.2.1/dist/aframe-event-set-component.min.js"></script>
                <script src="https://unpkg.com/aframe-orbit-controls@1.0.0/dist/aframe-orbit-controls.min.js"></script>
            </Helmet>
            <Map />
        </Layout>
    );
};

export default MapExperience;
