import React, { useEffect, useState } from "react";
import Loader from "../../general/Loader";
import "./style.css";

const Map = props => {
    const [isInit, setIsInit] = useState(null);
    useEffect(() => {
        if (isInit === null) {
            setTimeout(() => setIsInit(true), 1000);
        }
    });
    if (isInit === null) {
        return <Loader />;
    }

    return (
        <div>
            <a-scene
                id="floor-map"
                keyboard-shortcuts=""
                vr-mode-ui="enabled: false"
                device-orientation-permission-ui=""
            >
                <a-assets>
                    <a-asset-item
                        id="map-obj"
                        src="/experiences/map2/model/visitor-map.obj"
                    ></a-asset-item>
                    <a-asset-item
                        id="map-mtl"
                        src="/experiences/map2/model/visitor-map.mtl"
                    ></a-asset-item>
                    <img
                        id="left"
                        src="/experiences/map2/img/symbols/lift.png"
                    />
                    <img
                        id="stairs"
                        src="/experiences/map2/img/symbols/stairs.png"
                    />
                    <img
                        id="cafe"
                        src="/experiences/map2/img/symbols/cafe.png"
                    />
                    /&gt;
                    <img
                        id="lift"
                        src="/experiences/map2/img/symbols/lift.png"
                    />
                    <img
                        id="coffee-pod"
                        src="/experiences/map2/img/symbols/coffee-pod.png"
                    />
                    <img
                        id="tooltip-board"
                        src="/experiences/map2/img/symbols/tooltip-board.png"
                    />
                    <img
                        id="tooltip-pointer"
                        src="/experiences/map2/img/symbols/tooltip-pointer.png"
                    />
                    <img
                        id="toilets"
                        src="/experiences/map2/img/symbols/toilets.png"
                    />
                </a-assets>

                <a-entity position="0 6 10" rotation="-11.25 0 0">
                    <a-camera
                        id="camera"
                        position="0 0 32"
                        look-controls="enabled: false"
                        orbit-controls="target: 0 2.5 0; minDistance: 0.01; maxDistance: 180; initialPosition: 0 2.5 5"
                        camera="near: 0.1"
                        rotation=""
                        data-aframe-inspector-original-camera=""
                        wasd-controls=""
                    ></a-camera>
                </a-entity>

                <a-entity
                    id="mouseCursor"
                    cursor="rayOrigin: mouse"
                    raycaster="objects: .clickable; useWorldCoordinates: true; direction: 0.04740848333682278 0.15283122925494388 -0.9871145075785914; origin: -5.026983260073967 10.053417237655722 33.95048727117037"
                ></a-entity>
                <a-entity id="all-floors">
                    <a-entity position="" rotation="">
                        <a-obj-model
                            src="#map-obj"
                            mtl="#map-mtl"
                            scale="1 0.72 1.07"
                            material="shader: flat; side: double"
                            obj-model=""
                            position="-12.22 0 -61.29"
                            rotation=""
                            visible=""
                        ></a-obj-model>
                    </a-entity>

                    <a-entity
                        id="pillar"
                        geometry="depth: 1; height: 15.2; width: 1"
                        position="-2 7.79 -1.67"
                    ></a-entity>
                    <a-entity
                        id="lift-shaft"
                        geometry="depth: 1.78; height: 15.2; width: 1.96"
                        position="12.7118 7.79 -1.67"
                    ></a-entity>

                    <a-entity
                        id="floor-0"
                        position=""
                        scale=""
                        class="clickable"
                    >
                        <a-entity
                            scale="0.1 4.81 3.34"
                            position="1.90108 0.53258 -0.08278"
                            id="stair-to-1"
                        >
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.1 -0.1"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.2 -0.2"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.3 -0.3"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.4 -0.4"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0.01 0.5 -0.5"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.6 -0.6"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.7 -0.7"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.8 -0.8"
                            ></a-box>
                        </a-entity>
                        <a-image
                            id="floor-0-toilet"
                            src="#toilets"
                            width="1"
                            height="1"
                            scale="2 2 1"
                            position="-4.87404 1.82772 -1.54837"
                            material=""
                            geometry=""
                        ></a-image>
                        <a-image
                            id="floor-0-cafe"
                            src="#cafe"
                            width="1"
                            height="1"
                            scale="2 2 1"
                            position="-10.65691 1.68957 -3.41035"
                            material=""
                            geometry=""
                        ></a-image>
                        <a-image
                            id="floor-0-coffee-pod"
                            src="#coffee-pod"
                            width="1"
                            height="1"
                            scale="2 2 1"
                            position="8.60687 1.78313 -0.11564"
                            material=""
                            geometry=""
                        ></a-image>
                        <a-image
                            id="floor-0-lift-1"
                            src="#lift"
                            width="1"
                            height="1"
                            scale="1.5 1.5 1.5"
                            position="13.1628 2.10615 0.05518"
                            material=""
                            geometry=""
                        ></a-image>

                        <a-entity
                            id="imax-tooltip"
                            position="11.5 2.5 7.5"
                            text__imax-txt="height: 4; value: Cineworld IMAX; width: 4; align: center; wrapCount: 10"
                        >
                            <a-image
                                src="#tooltip-pointer"
                                width="1"
                                height="1"
                                scale="5 5 1"
                                position="-0.13918 0.68226 -0.09218"
                                material="side: front"
                                id="imax-pointer"
                            ></a-image>
                            <a-image
                                src="#tooltip-board"
                                width="1"
                                height="1"
                                scale="5.48 2.71 2"
                                position="-0.02292 0 -0.05338"
                                id="imax-board"
                            ></a-image>
                        </a-entity>
                    </a-entity>
                    <a-entity id="floor-1" position="" scale="">
                        <a-entity
                            scale="0.08 4.32 2.84"
                            position="-12.65509 4.787 -3.98045"
                            id="stair-to-2"
                            rotation="0 -90 0"
                        >
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.1 -0.1"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.2 -0.2"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.3 -0.3"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.4 -0.4"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.5 -0.5"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.6 -0.6"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.7 -0.7"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.8 -0.8"
                            ></a-box>
                        </a-entity>

                        <a-image
                            id="floor-1-lift-1"
                            src="#lift"
                            width="1"
                            height="1"
                            scale="1.5 1.5 1.5"
                            position="-0.32063 5.673 -1.76461"
                            material=""
                            geometry=""
                        ></a-image>
                        <a-image
                            id="floor-1-lift-2"
                            src="#lift"
                            width="1"
                            height="1"
                            scale="1.5 1.5 1.5"
                            position="13.33331 6.01855 -0.70492"
                            material=""
                            geometry=""
                        ></a-image>
                    </a-entity>
                    <a-entity id="floor-2" position="" scale="">
                        <a-entity
                            scale="0.08 3.87 1.42"
                            position="-11.45745 8.25997 -2.8265"
                            id="stair-to-3"
                            rotation="180 90 180"
                        >
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.1 -0.1"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.2 -0.2"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.3 -0.3"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.4 -0.4"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.5 -0.5"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.6 -0.6"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.7 -0.7"
                            ></a-box>
                            <a-box
                                width="8"
                                height="0.1"
                                material="flatShading: true; color: #006d8f"
                                geometry="depth: 0.1"
                                position="0 0.8 -0.8"
                            ></a-box>
                        </a-entity>
                    </a-entity>
                    <a-entity id="floor-3" position="" scale="">
                        <a-image
                            id="floor-3-lift"
                            src="#lift"
                            width="1"
                            height="1"
                            scale="1.5 1.5 1.5"
                            position="11.66475 9.71676 0.1349"
                            material=""
                            geometry=""
                        ></a-image>
                        <a-image
                            id="floor-3-toilets"
                            src="#toilets"
                            width="1"
                            height="1"
                            scale="2 2 2"
                            position="7.44752 9.54425 -0.54531"
                            material=""
                            geometry=""
                        ></a-image>
                    </a-entity>
                </a-entity>

                <a-light
                    type="point"
                    color="#94c6ff"
                    distance="17"
                    position="0 10.37039 8.25069"
                    intensity="1"
                    light=""
                ></a-light>
                <a-light
                    type="ambient"
                    color="#4f6487"
                    light=""
                    position=""
                    scale="1 0.00001 1"
                ></a-light>
            </a-scene>
            <div id="legend-overlay" className="legend-overlay">
                <img
                    id="legend"
                    src="/experiences/map2/img/symbols/legend.png"
                />
            </div>
        </div>
    );
};

export default Map;
